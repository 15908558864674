<template>
  <div class="has-nav-bar">
    <van-nav-bar
      title="人员变更"
      fixed
      left-arrow
      @click-left="$router.back()"
    />
    <div>
      <van-form ref="form">
        <van-field
          v-model="workTypeName"
          readonly
          is-link
          name="workType"
          label="作业类别"
          input-align="right"
          placeholder="请选择"
          @click="workTypeClick"
        />
        <van-field
          v-model="workerTypeName"
          readonly
          is-link
          name="workerType"
          label="人员类型"
          input-align="right"
          placeholder="请选择"
          :rules="[{ required: true, message: ' ' }]"
          @click="workerTypeClick"
        />
        <van-field
          v-if="localSectionChiefVisible"
          :value="getPersonLabel(form.localSectionChiefList)"
          readonly
          is-link
          name="localSectionChiefList"
          label="属地工段长"
          input-align="right"
          placeholder="请选择"
          :rules="[{ required: true, message: ' ' }]"
          @click="localSectionChiefClick"
        />
        <van-field
          v-model="currentPersonName"
          readonly
          is-link
          name="currentPersonList"
          label="变更前人员"
          input-align="right"
          placeholder="请选择"
          :rules="[{ required: true, message: ' ' }]"
          @click="currentPersonClick"
        />
        <van-field
          :value="getPersonLabel(form.modifiedPersonList)"
          readonly
          is-link
          name="modifiedPersonList"
          label="变更后人员"
          input-align="right"
          placeholder="请选择"
          :rules="[{ required: true, message: ' ' }]"
          @click="modifiedPersonClick"
        />
        <van-field
          v-model="form.modifyCause"
          rows="1"
          autosize
          label="变更原因"
          type="textarea"
          placeholder="请输入内容"
          maxlength="200"
          input-align="right"
          :rules="[{ required: true, message: ' ' }]"
        />
      </van-form>
      <div class="jiange"></div>
      <div class="WorkticketSafetyItem block-space_8_notLast">
        <div class="title">安全交底确认</div>
        <van-checkbox v-model="checked" shape="square"
          >本人已掌握本次作业的内容及安全工作方案，并对作业内容及安全措施进行了现场检查，在作业过程中执行相关安全规定。</van-checkbox
        >
        <div class="tag-box">
          <Tag
            v-for="item in form.modifiedPersonList"
            :key="item.personId"
            :type="item.fileId ? 'green' : 'white'"
            :tick="!!item.fileId"
            @click.native="
              handleClickTag(
                item,
                item.fileId,
                item.personId,
                item.personType,
                id,
                item.specialId
              )
            "
          >
            {{ item.personName }}
          </Tag>
        </div>
      </div>
      <div
        v-if="localSectionChiefVisible"
        class="WorkticketSafetyItem block-space_8_notLast"
      >
        <div class="title">属地工段长签名</div>
        <div class="tag-box">
          <Tag
            v-for="item in form.localSectionChiefList"
            :key="item.personId"
            :type="item.fileId ? 'green' : 'white'"
            :tick="!!item.fileId"
            @click.native="
              handleClickTag(
                item,
                item.fileId,
                item.personId,
                item.personType,
                id,
                item.specialId
              )
            "
          >
            {{ item.personName }}
          </Tag>
        </div>
      </div>

      <select-popup
        :choose-value="workType"
        :data="workTypes"
        title="作业类别"
        :visible="workTypePopupVisible"
        show-filter
        value-key="specialId"
        label-key="specialName"
        work-type-name
        :popup-style="{
          height: '50vh'
        }"
        @close="workTypePopupVisible = false"
        @confirm="workTypePopupConfirm"
      />
      <select-popup
        :choose-value="form.workerType"
        :data="workerTypes"
        title="人员类型"
        :visible="workerTypePopupVisible"
        show-filter
        :popup-style="{
          height: '50vh'
        }"
        @close="workerTypePopupVisible = false"
        @confirm="workerTypePopupConfirm"
      />
      <SelectPopupGrade
        ref="selectPopupGrade"
        v-model="form.currentPersonList"
        :immediately="false"
        list-key="value"
        title="当前人员"
        :list="currentPersons"
        :visible.sync="currentPersonPopupVisible"
        :multi="modifiedPersonMultiple"
        output-type="array"
        @change="currentPersonChange"
      />
      <WorkticketPersonPicker
        ref="personPicker"
        v-model="modifiedPersonList"
        :title="workerTypeName"
        name="modifiedPersonList"
        :multiple="modifiedPersonMultiple"
        :department="workTypeInfo.department"
        :contractor-status="workTypeInfo.contractorStatus"
        :dept-code="workTypeInfo.deptCode"
        :work-type-id="workTypeInfo.workTypeId"
        :special-id="form.workerType !== '15' ? workTypeInfo.specialId : ''"
        :is-worker="!isHoisting && form.workerType !== '1'"
        :is-hoisting="isHoisting"
        :show-license-tag="showLicenseTag"
        :license-types="licenseTypesSwitch(form.workerType)"
        @change="modifiedPersonChange"
      />
      <select-person-by-tabs
        :key="`${form.workerType}-${workTypeInfo.workTypeId}`"
        :title="dynamicTitle"
        :visible="selectPersonByTabsVisible"
        :show-department-name="false"
        :show-phone-number="true"
        position="bottom"
        :include-children="true"
        :multiple="modifiedPersonMultiple"
        :is-department-auth="false"
        :default-department="workTypeInfo.department"
        :default-contractor="
          workTypeInfo.contractorStatus ? workTypeInfo.deptCode : ''
        "
        :work-type-id="
          ['3', '15'].includes(form.workerType) ? '' : workTypeInfo.workTypeId
        "
        :license-types="licenseTypesSwitch(form.workerType)"
        :contractor-license-types="
          form.workerType === '3' ? workTypeInfo.licenseType : ''
        "
        :recent-type="form.workerType"
        :contractor-worker-type="form.workerType"
        :choose-value="choosePersonSelect"
        @close="selectPersonByTabsVisible = false"
        @confirm="onPersonConfirm"
      />
      <select-person
        :title="SPTitle"
        position="bottom"
        :visible="selectPersonVisible"
        :choose-value="
          isLocalSectionChief ? localSectionChiefSelected : choosePersonSelect
        "
        :show-department-name="false"
        :show-phone-number="true"
        :is-department-auth="false"
        :show-department-search="form.workerType !== '1'"
        :default-department="SPDefaultDepartment"
        :license-types="licenseTypesSwitch(form.workerType)"
        :contractor-worker-type="form.workerType"
        :include-children="true"
        :multiple="modifiedPersonMultiple"
        @close="selectPersonVisible = false"
        @confirm="onPersonConfirm"
      />
    </div>
    <bottom-fixed-box>
      <van-button class="btn-default" @click="$router.back()">取消</van-button>
      <van-button
        :class="submitBtnDisabled ? 'btn-disabled' : 'btn-primary'"
        @click="handlerConfirm"
        >提交
      </van-button>
    </bottom-fixed-box>
  </div>
</template>

<script>
import SelectPopup from "@/components/SelectPopup";
import SelectPopupGrade from "@/components/SelectPopupGrade";
import WorkticketPersonPicker from "@/components/picker/WorkticketPersonPicker";
import Tag from "@/components/Tag";
import esign from "@/components/esign";
import BottomFixedBox from "@/components/general/BottomFixedBox";
import {
  getWorkType,
  getWorkPerson,
  getWorkCurrentPerson,
  addWorkSignature,
  modifyPerson,
  getWorkticketBaseInfo
} from "@/api/workticket";
import { fileUrl } from "@/utils/constant";
import projectTextConfig from "@/utils/projectTextConfig";

export default {
  name: "PersonChange",
  components: {
    SelectPopup,
    SelectPopupGrade,
    WorkticketPersonPicker,
    Tag,
    BottomFixedBox
  },
  data() {
    const fieldProps = {
      border: true,
      placeholder: "请输入",
      clearable: true,
      "input-align": "right",
      "error-message-align": "right"
    };
    return {
      id: this.$route.params.id || "",
      form: {
        ticketId: this.$route.params.id || "",
        // 作业人员类型 1 负责人 2 监护人 3 作业人 4 安全分析负责人 5 气体检测分析人 6审批人 7受影响方 8验收人 9作业批准人 10送电人
        // 11 指挥人员 12 吊装作业人 13 生产单位作业指挥 15 一般作业人
        workerType: "",
        localSectionChiefList: [], // 属地工段长
        currentPersonList: [], // 当前人员
        modifiedPersonList: [], // 变更后人员
        modifyCause: "", // 变更原因
        logId: ""
      },
      workTypeInfo: {},
      workTypePopupVisible: false,
      workTypes: [],
      workTypeName: "",
      workType: "", // 实际上是小票id
      workerTypePopupVisible: false,
      workerTypeName: "",
      workerTypes: [],
      currentPersonName: "",
      currentPersons: [],
      currentPersonPopupVisible: false,
      modifiedPersonName: "",
      modifiedPersons: [],
      modifiedPersonPopupVisible: false,
      modifiedPersonMultiple: false,
      modifiedPersonList: [],
      oldWorkType: "",
      oldWorkerType: "",
      checked: true,
      selectPersonVisible: false,
      choosePersonSelect: [],
      selectPersonByTabsVisible: false,
      // SPCheckedByWorkerType: {
      //   // 5检测分析人、13生产单位作业指挥
      //   5: [],
      //   13: []
      // }
      localSectionChiefFlag:
        window.globalConfig.VUE_APP_WORK_LOCAL_SECTION_CHIEF === "true",
      isLocalSectionChief: false,
      localSectionChiefSelected: [],
      baseInfo: {}
    };
  },
  computed: {
    //提交按钮判断是否可点击
    submitBtnDisabled() {
      const personList = this.form.modifiedPersonList;
      let find = personList.find(item => !item.fileId);
      if (this.localSectionChiefVisible && !find) {
        find = this.form.localSectionChiefList.find(item => !item.fileId);
      }
      return find ? true : false;
    },
    SPTitle() {
      if (this.localSectionChiefVisible) {
        return "属地工段长(多选)";
      }
      const obj = {
        5: `${projectTextConfig.TEXT_WORK_ANALYST}(单选)`,
        // 10: "用电人(单选)",
        13: `${this.TEXT_PRODUCTION_UNIT_WORK_COMMANDER}(单选)`
      };
      return obj[this.form.workerType] || "选择人员";
    },
    SPDefaultDepartment() {
      return this.form.workerType === "10" ? "" : this.workTypeInfo.department;
    },
    isHoisting() {
      const arr = ["11", "12"];
      return arr.includes(this.form.workerType);
    },
    showLicenseTag() {
      const arr = ["3", "11", "12"];
      return arr.includes(this.form.workerType);
    },
    // choosePersonSelect() {
    //   return this.SPCheckedByWorkerType[this.form.workerType] || [];
    // }
    dynamicTitle() {
      const title = this.workerTypeName;
      const state = this.modifiedPersonMultiple ? "多选" : "单选";
      return `${title}(${state})`;
    },
    localSectionChiefVisible() {
      return this.localSectionChiefFlag && this.form.workerType === "2";
    }
  },
  watch: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {
    this.getWorkType();
  },
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  methods: {
    async getWorkType() {
      this.baseInfo = await getWorkticketBaseInfo(this.id);
      const res = await getWorkType(this.id);
      this.workTypes = res.map(item => {
        const find = this.baseInfo.workTypeList.find(
          i => item.workTypeId === i.id
        );
        // 作业活动配置的“特种作业人员所需资质”
        item.licenseType = this.licenseTypeFormatter(
          (find?.licenseType || [])[0]
        );
        return item;
      });
      if (this.workTypes.length === 1) {
        this.workTypePopupConfirm(this.workTypes[0]);
      }
    },
    workTypeClick() {
      this.workTypePopupVisible = true;
      this.oldWorkType = this.workType;
    },
    workTypePopupConfirm(row) {
      if (this.oldWorkType === row.workTypeId) return;
      this.workType = row.specialId;
      this.workTypeName = row.specialName;
      this.workTypeInfo = row;
      // 上层数据改变清除下层所有数据
      // 清空人员类型数据
      this.workerTypes = [];
      this.form.workerType = "";
      this.workerTypeName = "";
      // 清空当前人员数据
      this.currentPersons = [];
      this.currentPersonName = "";
      this.form.currentPersonList = [];
      // 清空变更后人员数
      this.form.modifiedPersonList = [];
      this.modifiedPersonList = [];
      this.choosePersonSelect = [];
      // this.clearSPCheckedData();
      this.localSectionChiefSelected = [];
    },
    async workerTypeClick() {
      // 作业人员类型 1 负责人 2 监护人 3 作业人 4 安全分析负责人 5 气体检测分析人 6审批人 7受影响方 8验收人 9作业批准人 10送电人
      // 11 指挥人员 12 吊装作业人 13 生产单位作业指挥 15 一般作业人
      this.oldWorkerType = this.form.workerType;
      if (!this.workType) {
        this.workerTypes = [];
        this.workerTypePopupVisible = true;
        return;
      }
      const find = this.workTypes.find(
        item => item.specialId === this.workType
      );
      this.workerTypes = await getWorkPerson({ specialId: find.specialId });
      this.workerTypePopupVisible = true;
    },
    workerTypePopupConfirm(row) {
      // 影响下一级变动
      if (this.oldWorkerType === row.value) return;
      this.form.workerType = row.value || "";
      this.workerTypeName = row.label || "";
      // 单选: 1作业负责人、5检测分析人、7受影响方、10送电人、13生产单位作业指挥、2601用电单位负责人
      // 多选：3作业人、15一般作业人，2监护人是多选、11指挥人员、12吊装作业人。
      const singleWhiteList = ["1", "5", "7", "10", "13", "2601"];
      this.modifiedPersonMultiple = singleWhiteList.includes(row.value)
        ? false
        : true;
      // 清空当前人员数据
      this.currentPersons = [];
      this.currentPersonName = "";
      this.form.currentPersonList = [];
      // 清空变更后人员数据
      this.form.modifiedPersonList = [];
      this.modifiedPersonList = [];
      this.choosePersonSelect = [];
      // this.clearSPCheckedData();
      if (row.value !== "2") {
        this.form.localSectionChiefList = [];
        this.localSectionChiefSelected = [];
      }
    },
    async currentPersonClick() {
      // 没有大票id || !this.form.workerType || 没有小票id(this.workType)
      if (!this.id || !this.form.workerType || !this.workType) {
        this.currentPersons = [];
        this.currentPersonPopupVisible = true;
        return;
      }
      const res = await getWorkCurrentPerson(this.workType, {
        workerType: this.form.workerType
      });
      this.currentPersons = res.map(i => {
        i.value = i.id;
        i.label = i.name;
        return i;
      });
      this.currentPersonPopupVisible = true;
    },
    currentPersonChange(val) {
      this.currentPersonName = val.map(item => item.label).toString();
    },
    async modifiedPersonClick() {
      switch (this.form.workerType) {
        case "2":
        case "3":
        case "10":
        case "11":
        case "12":
        case "15":
        case "1201":
        case "2601":
          this.selectPersonByTabsVisible = true;
          break;
        // case "1":
        case "5":
        case "13":
          this.selectPersonVisible = true;
          break;
        default:
          this.$nextTick(() => {
            this.$refs.personPicker.show();
          });
      }
    },
    getPersonLabel(persons) {
      if (!persons) return "";
      if (Array.isArray(persons)) {
        return persons.map(item => item.label || item.personName).join(",");
      }
      return persons.label || persons.personName;
    },
    modifiedPersonChange(val) {
      const checkPersonList = [];
      const data = val ? (Array.isArray(val) ? val : [val]) : [];
      data.forEach(item => {
        const find = this.form.modifiedPersonList.find(
          f => f.personId === item.value
        );
        let obj = { ...find };
        if (!find) {
          obj = {
            fileId: "",
            personType: this.form.workerType,
            orgType: item.type || item.type === 0 ? item.type : item.personType,
            personId: item.value,
            personName: item.label,
            ticketId: this.id,
            specialId: this.workType
          };
        }
        checkPersonList.push(obj);
      });
      this.form.modifiedPersonList = checkPersonList;
    },
    localSectionChiefChange(val) {
      const checkPersonList = [];
      const data = val ? (Array.isArray(val) ? val : [val]) : [];
      data.forEach(item => {
        const find = this.form.localSectionChiefList.find(
          f => f.personId === item.value
        );
        let obj = { ...find };
        if (!find) {
          obj = {
            fileId: "",
            personType: "3402",
            orgType: item.type || item.type === 0 ? item.type : item.personType,
            personId: item.value,
            personName: item.label,
            ticketId: this.id,
            specialId: this.workType
          };
        }
        checkPersonList.push(obj);
      });
      this.form.localSectionChiefList = checkPersonList;
    },
    onPersonConfirm(ids, list) {
      if (this.isLocalSectionChief) {
        this.localSectionChiefChange(list);
        this.localSectionChiefSelected = list;
        this.isLocalSectionChief = false;
      } else {
        this.modifiedPersonChange(list);
        this.choosePersonSelect = list;
      }
      // this.SPCheckedByWorkerType[this.form.workerType] = list;
      // 分析人
    },
    handleClickTag(item, fileId, personId, personType, ticketId, specialId) {
      if (fileId) {
        //已上传过，直接预览
        esign.showImage(fileUrl + fileId);
        return;
      }
      //
      esign.show(imageRes => {
        fileId = imageRes.id;
        item.fileId = fileId;
        // addWorkSignature({
        //   fileId,
        //   personId,
        //   personType,
        //   ticketId,
        //   specialId
        // })
        //   .then(res => {
        //     item.fileId = fileId;
        //     this.$toast.success("提交成功");
        //   })
        //   .catch(error => {
        //     this.$toast.fail("签名失败，请重试");
        //   });
      });
    },
    handlerConfirm() {
      this.$refs.form.validate().then(async res => {
        const params = { ...this.form };
        params.signatureList = [
          ...params.modifiedPersonList,
          ...params.localSectionChiefList
        ];
        params.modifiedPersonList = params.modifiedPersonList.map(item => {
          const orgType =
            item.orgType || item.orgType === 0 ? String(item.orgType) : "";
          return {
            label: item.personName,
            value: item.personId,
            id: item.personId,
            name: item.personName,
            type: orgType || item.personType
          };
        });
        const cpl = params.currentPersonList;
        params.currentPersonList = this.currentPersons.filter(i =>
          cpl.includes(i.id)
        );
        await modifyPerson(this.workType, params);
        this.$toast.success("提交成功");
        this.$router.back();
      });
    },
    licenseTypesSwitch(workerType) {
      // 特种作业人使用“特种作业人员所需资质”
      if (workerType === "3") {
        return this.workTypeInfo.licenseType || "";
      }
      if (
        !this.workTypeInfo.licenseTypes ||
        this.isLocalSectionChief ||
        workerType === "15" // 一般作业人不校验资质（可选全部）
      ) {
        return "";
      }
      const find = (this.workTypeInfo.licenseTypes || []).find(t => {
        return Number(t.workerType) === Number(workerType);
      });
      if (find?.licenseTypes?.length) {
        return find.licenseTypes
          .map(i => {
            const t = i.replace(/,/g, "-");
            return t;
          })
          .toString();
      }
      return "";

      // console.log('find>>>>>>>>>>>.', find)
      // if (this.isHoisting) {
      //   return find?.licenseTypes ? find.licenseTypeList
      //           .map(i => {
      //             const t = i.replace(/,/g, "-");
      //             return t;
      //           })
      //           .toString()
      //       : "";
      // }
      // return (find && find.licenseTypes) || "";
    },
    // clearSPCheckedData() {
    //   const keys = Object.keys(this.SPCheckedByWorkerType);
    //   keys.forEach(item => {
    //     this.SPCheckedByWorkerType[item] = [];
    //   });
    // }
    localSectionChiefClick() {
      this.isLocalSectionChief = true;
      this.selectPersonVisible = true;
    },
    licenseTypeFormatter(val) {
      return val ? val.replace(/,/g, "-") : "";
    }
  }
};
</script>

<style lang="scss" scoped>
.jiange {
  width: 100vw;
  height: 8px;
  background-color: #eceef2;
}
.WorkticketSafetyItem {
  padding: 16px;

  .title {
    color: $--color-black-title;
    font-size: 16px;
    margin-bottom: 10px;
  }

  .title1 {
    color: $--color-black-title;
    font-size: 14px;
    margin-bottom: 10px;

    &:before {
      display: inline-block;
      content: " ";
      width: 3px;
      height: 12px;
      background: $--color-primary;
      border-radius: 2px;
      margin-right: 3px;
    }
  }

  .van-checkbox {
    align-items: flex-start;
    margin-bottom: 10px;
    pointer-events: none;
    ::v-deep .van-checkbox__label {
      font-size: 12px;
      text-align: justify;
      color: #8c8f97;
    }
  }
  .btn-select-person {
    display: block;
    width: 86px;
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    font-weight: 400;
    text-align: center;
    background-color: #1676ff;
    color: #ffffff;
    padding: 0;
    margin: 0 0 16px;
  }

  .tag-box {
    margin-bottom: 16px;
  }
}
</style>
